import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import * as main from '../script'
import * as scenes from '../questions/scenes'
import * as uiControl from '../ui_controller/ui_controller'
import * as THREE from 'three'
import { fresnel } from '../shader'
import { shaderMaterial, outerCharacterShaders, lowFresnelMaterial } from '../fresnel'
import { MathUtils, TextureLoader } from 'three'

let preModelsLoaded = false
let postModelsLoaded = false

// history.back()
// let popHandler = () => {
//     console.log('pop up')
//     if (confirm('Your progress will be lost. Do you want to go back?')) {
//       window.history.back() 
//     } else {
//       window.history.forward()
//       setTimeout(() => {
//         window.addEventListener('popstate', popHandler, {once: true})
//       }, 50) // delay needed since the above is an async operation for some reason
//     }
// }
// window.addEventListener('popstate', popHandler, {once: true})
// window.history.pushState(null,null,null)

window.onbeforeunload = function (e) {
    e = e || window.event;

    // For IE and Firefox prior to version 4
    if (e) {
        e.returnValue = 'Sure?';
    }

    // For Safari
    return 'Sure?';
};

// window.history.pushState({page: 1}, "", "");
// history.back();
// history.forward();
// window.addEventListener('onpopstate', function(event) {
//   if(event){
//     var confirm = window.confirm("Please, note that you may lose your move details by returning to the previous page.");
//   }
// })

//      Initializing loader module properties
//
//Object to store loaded models
//Add empty keys for each model loaded to calculate loading percentage
let models = {
    playerCharacter:null,
    playerOutline:null,
    distantFriend:null,
    mother:null,
    father:null,
    siblings:null,
    friends:null,
    community:null,
    centerEmoji:null,
    sriLankaMap:null,
    sriLankaProvincesMap:null,
    maldivesMap:null,
    maldivesProvincesMap:null,
    dif_language: null,
    home_country: null,
    religious_belief: null,
    temples:null,
    landstage3:null,
    Tree1:null,
    Tree2:null,
    Tree3:null,
    Tree4:null,
    Tree5:null,
    Tree6:null,
    Tree7:null,
    Tree8:null,
    Tree9:null,
    Tree10:null,
    cloud1:null,
    cloud2:null,
    cloud3:null,
    carpet:null,
    lamp:null,
    sofa:null,
    sofasmall:null,
    letter:null,
    adventurer:null, // high LOC
    mapMaker:null, // mid LOC
    changeSeeker:null, // low LOC
    //Scene Filling characters
    friend1:null,
    friend2:null,
    friend3:null,
    friend4:null,
    friend5:null,
    friend6:null,
    friend7:null,
    friend8:null,
    house1:null,
    house2:null,
    house3:null
}

const preLoadModels = [
    'playerCharacter','playerOutline','mother','father','lamp','carpet',
    'sriLankaMap','sriLankaProvincesMap','maldivesMap','maldivesProvincesMap'
    // 'Tree1','Tree2','Tree3','Tree4','Tree5','Tree6','Tree7','Tree8','Tree9','Tree10',
    // 'cloud1','cloud2','cloud3'
]

//player animations are stored here
let animations = {
    playerCharacter:null,
    playerOutline:null,
    distantFriend:null,
    centerEmoji:null,
    father:null,
    mother:null,
    siblings:null,
    friends:null,
    community:null,
    dif_language: null
}

let animationId = {
    playerCharacter:{
        'idle':1,   //0
        'wait':2,
        'startL':6, //5
        'walkL' :7,  //6
        'stopL' :8,  //7 
        'startR':9, //8
        'walkR' :10,  //9
        'stopR' :11, //10
        'jumpStart':3,  //2
        'onJump'   :4,  //3
        'jumpStop' :5   //4
    },
    playerOutline:{
        'idle':1,   //0
        'wait':2,
        'startL':6, //5
        'walkL' :7,  //6
        'stopL' :8,  //7 
        'startR':9, //8
        'walkR' :10,  //9
        'stopR' :11, //10
        'jumpStart':3,  //2
        'onJump'   :4,  //3
        'jumpStop' :5   //4
    },
    distantFriend:{
        'idle':1
    },
    father:{
        'idle':1
    },
    mother:{
        'idle':1
    },
    siblings:{
        'idle':1
    },
    friends:{
        'idle':1
    },
    dif_language:{
        'idle':1
    }
}

//#region Setting up progress bars
//assign the number assets imported in this module
// const numberOfAssets = Object.keys(models).length
const numberOfAssets = preLoadModels.length
console.log("Number of preload assets = ", numberOfAssets)

//value representing how much has loaded,
//ranging from 0 to 1. 
//Adds a percentage when an asset is loaded.
//Value should be 100 when all assets are loaded.
let loadedPercentage = 0 
//
//      end of Initializing loader module properties

//      Creating a progress bar
//
let progressBar = require('progressbar.js')
let loadingBar = new progressBar.Circle('#progress-bar-container' /* Element that will contain SVG generated by progressbar.js */,{ 
    //add necessary styling/properties. Refer https://progressbarjs.readthedocs.io/en/latest/api/shape/ 
    trailColor:"#cfcfcf",
    trailWidth:"7",
    strokeWidth:"7",
    color:'url(#loading-bar-gradient)',
    text:{
        className:"progress-bar-text",
        autoStyleContainer:"false",
        style:{
            // position:"absolute",
            // top:"0px",
            width:"fit-content",
            height:"fit-content",
            color:"white",
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            fontWeight:"600",
            zIndex:"3",
            fontFamily: '"Rubik",sans-serif',
            fontSize: '1em',
            opacity:'1',
            margin:"0 auto"
        }
    },
    step: function(state, circle) {
        var value = Math.round(circle.value() * 100);
        circle.setText(value);
        if(value >= 100){
            // main.startSurvey()
            uiControl.enableStartSurvey()
            loadPostModels()
        }
    },
    svgStyle:{
        position:"relative",
        strokeLinecap:"round",
        zIndex:"3",
        display:'none'
    }
})

const loadingBarGradient = `
    <defs>
        <linearGradient id="loading-bar-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style="stop-color:#FF9494;stop-opacity:1" />
            <stop offset="100%" style="stop-color:#DAFD04;stop-opacity:1" />
        </linearGradient>
    </defs>
`

loadingBar.svg.insertAdjacentHTML('afterbegin',loadingBarGradient)

// document.getElementById('progress-bar-background').style.display = ''

//set the progress bar value to 0
loadingBar.animate(loadedPercentage)
//#endregion

//      Loading models
//
const gltfloader = new GLTFLoader()

//Boilerplate for importing a model. Change variable names and path to the asset
//Once a model is loaded, add it to the models object
/* 
gltfloader.load(
    'Model path',
    (gltf2) =>
    {
        models['modelKey'] = gltf2.scene; // adding the model to the models object
        //set initial properties here

        loadedPercentage += (1/numberOfAssets) //calculate the percentage the asset contributes to the total loadedPercentage
        loadingBar.animate(loadedPercentage) // animate the progress bar
        if(loadedPercentage >= 1){ //if loadedPercentage is 1, then the survey can start.
            //Call function to start the survey
        }
    }
)  
*/
//#region Texture Setting for toon materials
const fiveTone = new THREE.DataTexture(
    Uint8Array.from([0, 0, 0, 255 ,
        64, 64,64,255,
         128, 128, 128, 255,
         180,180,180,255,
          255, 255, 255,255])
          ,5,1,THREE.RGBAFormat
);

const threeTone = new THREE.DataTexture(
    Uint8Array.from([0, 0, 0, 255 ,
         128, 128, 128, 255,
          255, 255, 255,255])
          ,3,1,THREE.RGBAFormat
);

const tex = new THREE.TextureLoader().load('Textures/grad.png');
tex.minFilter = THREE.NearestFilter;
tex.magFilter = THREE.NearestFilter;
//#endregion

function loadInitialModels(){
    if(preModelsLoaded)
        return
    else
        preModelsLoaded = true

    //Importing player character
    gltfloader.load(
        'Models/Animation_V11.gltf',       //'Models/toonwalk_character.gltf'
        (gltf) =>
        {
            const key = 'playerCharacter'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = 'player';
            model.scale.set(.3,.3,.3)        //0.07 prev
            model.position.set(0,-.6, 2)
    
            var toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
            //toonMaterial = new THREE.MeshStandardMaterial({color : 0xFFC332, roughness : 0.8, metalness : 0.2});
            const shader = {
                'outline' :
                {
                    vertex_shader: [
                        "uniform float offset;",
                        "void main() {",
                        "vec4 pos = modelViewMatrix * vec4( position + normal * offset, 1.0 );",
                        "gl_Position = projectionMatrix * pos;",
                        "}"
                    ].join("\n"),
        
                    fragment_shader: [
                        "void main(){",
                        "gl_FragColor = vec4( 1.0, 0.0, 0.0, 1.0 );",
                        "}"
                    ].join("\n")
                }
            };
            const outShader = shader['outline'];
            const uniforms = {offset: {
                type: "f",
                value: 1}
            };
    
            const matShader = new THREE.ShaderMaterial({
                uniforms: uniforms,
                vertexShader: outShader.vertex_shader,
                fragmentShader: outShader.fragment_shader
            });
            matShader.depthWrite = false;
    
            model.traverse((child) => {
                if (child.isMesh){
                    //new THREE.MeshBasicMaterial({color : 0xFFC332});
                    child.material = shaderMaterial; // material of the player character
                    child.castShadow = true;
                    child.rotation.set(3.5, 0, 0);
                }
            });
    
            models[key] = model; // adding the model to the models object
    
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets) //calculate the percentage the asset contributes to the total loadedPercentage
                loadingBar.animate(loadedPercentage) // animate the progress bar
            }
        }
    )  
    //Player outline
    gltfloader.load(
        'Models/Animation_V11.gltf',
        (gltf) =>
        {
            const key = 'playerOutline'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.34,.33,.33)
            model.position.set(0,-.6, 0)
    
            const mat = new THREE.MeshLambertMaterial({ color:'black', side : THREE.BackSide});
    
    
            model.traverse((child) => {
                if (child.isMesh){
                    child.material = mat;
                }
            });
    
            // mat.onBeforeCompile = (shader) => {
            //     const token = `#include <begin_vertex>`
            //     const customTransform = `
            //         vec3 transformed = vec3(position)  + objectNormal*0.006;
            //     `
            //     shader.vertexShader = 
            //         shader.vertexShader.replace(token,customTransform)
            // }
    
            models[key] = model// test center model.
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Mother
    gltfloader.load(
        'Models/mom.gltf',
        (gltf) =>
        {
            const key = 'mother'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)

            model.traverse((child) => {
                if (child.isMesh){
                    let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                    child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Father
    gltfloader.load(
        'Models/father.gltf',
        (gltf) =>
        {
            const key = 'father'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(0.045,0.045,0.045)     //prev (0.04,0.04,0.04) = player size
            model.position.set(0,-.6, 0)

            //
            model.traverse((child) => {
                if (child.isMesh){
                    if(child.name == 'Father'){
                        child.material = shaderMaterial;
                        child.castShadow = true;
                    }
                    // else if(child.name == 'EyeGlass001'){
                    //     var glassMat = new THREE.MeshLambertMaterial({color: 0xFFFFFF, transparent : true, opacity : 0.5});
                    //     child.material = glassMat;
                    //     child.castShadow = true;
                    // }
                    // else if(child.name == 'Moustache'){
                    //     var moustacheMat = new THREE.MeshToonMaterial({color: 0xafafaf, gradientMap: tex});
                    //     child.material = moustacheMat;
                    //     child.castShadow = true;
                    // }
                    else{
                        var transparentMat = new THREE.MeshBasicMaterial({transparent: true, opacity: 0});
                        child.material = transparentMat;
                    }
                }
            });

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Carpet
    gltfloader.load(
        'Models/carpet.gltf',
        (gltf) =>
        {
            const key = 'carpet'
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Lamp
    gltfloader.load(
        'Models/lamp.gltf',
        (gltf) =>
        {
            const key = 'lamp'
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //srilanka_provinces_cartoon_map
    gltfloader.load(
        'srilanka_provinces_cartoon_map.glb',
        (gltf) =>
        {
            const key = 'sriLankaProvincesMap'
            let model = gltf.scene
            model.scale.set(0.75,0.75,0.75)
            model.position.set(-1, 0, 0.5)
        
            models[key] = model
            
            // console.log(models['sriLankaProvincesMap']);
            //Setting up model for country selection
            // let countrySelectionModel = models['sriLankaProvincesMap'].clone(true)
            // countrySelectionModel.scale.set(0.35,0.35,0.35)
            // countrySelectionModel.position.set(-.4,0,0.1)
            // countrySelectionModel.children[10].castShadow = true
            // // console.log(countrySelectionModel);
        
        
            // scenes.sriLankaCube.add(countrySelectionModel)
        
            //Storing state colors as new properties
            // scenes.sriLankaCube.regionMaterial = countrySelectionModel.children[0].material//Material for all regions
        
            // scenes.sriLankaCube.standardColor = countrySelectionModel.children[0].material.color.clone()//standard color
            // scenes.sriLankaCube.hoveringColor =  new THREE.Color( 0xff0000 )//hovering color
            // scenes.sriLankaCube.selectedColor = new THREE.Color( 0x0000ff )//selected color
        
        
            //Setting up model for region selection
            let regionSelectionModel = models[key].clone(true) 
            regionSelectionModel.scale.set(0.15,0.15,0.15)
            regionSelectionModel.position.set(-5, 0, 0.5)
        
            regionSelectionModel.children[9].castShadow = true
            // console.log(regionSelectionModel);
            // console.log(regionSelectionModel);
            scenes.sriLankaScene.add(regionSelectionModel)
        
            //Setting up and filtering regions and setting up region state colors
            let sriLankaRegions = regionSelectionModel.children.slice() //Region references
            sriLankaRegions.splice(9,1)//Removes sealine and provincial divider from regions references
            // console.log(sriLankaRegions);
            // sriLankaRegions[0].position.y = 5
            // console.log(regionSelectionModel);
            let sriLankanRegionsMeshes = sriLankaRegions.map((region) => {
            
                const regionChildMesh = region.children[0]
            
                regionChildMesh.name = region.name.toLowerCase()

                // regionChildMesh.name = region.name.replaceAll('_',' ')
                // regionChildMesh.name = regionChildMesh.name + ' Province'
            
                

                console.log(regionChildMesh.name)
            
                regionChildMesh.regionMaterial = regionChildMesh.material.clone()//standard material
                regionChildMesh.regionMaterial.needsUpdate = true
                // console.log(regionChildMesh.regionMaterial);
                regionChildMesh.material = regionChildMesh.regionMaterial
                // console.log(regionChildMesh.material);
            
                regionChildMesh.standardMap = new THREE.TextureLoader().load('sri_lanka_provinces_standard.png')
                regionChildMesh.standardMap.needsUpdate = true
                regionChildMesh.standardMap.flipY = false
                regionChildMesh.regionMaterial.map = regionChildMesh.standardMap
            
                regionChildMesh.hoveringMap =  new THREE.TextureLoader().load('sri_lanka_provinces_hovering.png')
                regionChildMesh.hoveringMap.needsUpdate = true
                regionChildMesh.hoveringMap.flipY = false
                
                regionChildMesh.selectedMap = new THREE.TextureLoader().load('sri_lanka_provinces_selected.png')
                regionChildMesh.selectedMap.needsUpdate = true
                regionChildMesh.selectedMap.flipY = false
                
                // region.hoveringMaterial = region.material.clone()//hovering material
                // region.hoveringMaterial.color = new THREE.Color( 0xff0000 )
            
                // region.selectedMaterial = region.material.clone()//selected material
                // region.selectedMaterial.color = new THREE.Color( 0x0000ff )
            
                return regionChildMesh
            });
            // console.log(sriLankanRegionsMeshes);
        
            // model.castShadow = true
            // model.receiveShadow = true
        
            scenes.setSriLankaRegions(sriLankanRegionsMeshes)
        
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            // if(loadedPercentage >= 1){ //if loadedPercentage is 1, then the survey can start.
            //     //Call function to start the survey
            //     main.startSurvey()
            // }
        }
    )
    //maldives_provinces_cartoon_map
    gltfloader.load(
        'new_maldives_provinces_cartoon_map.glb',
        (gltf) =>
        {
            const key = 'maldivesProvincesMap'
            let model = gltf.scene
            // model.scale.set(0.38,0.38,0.38)
            // model.scale.set(0.3,0.3,0.3)
            model.position.set(0, 0, 0)
        
            models[key] = model
            // console.log(model);
            // console.log(model);
            //Setting up model for country selection
            // let countrySelectionModel = models['maldivesProvincesMap'].clone(true)
            // countrySelectionModel.scale.set(0.2,0.2,0.2)
            // countrySelectionModel.position.set(0,0,0)
            // countrySelectionModel.children[4].castShadow = true
            // scenes.maldivesCube.add(countrySelectionModel)
            
        
            // scenes.maldivesCube.regionMaterial = countrySelectionModel.children[0].material//Material for all regions
        
            // scenes.maldivesCube.standardColor = countrySelectionModel.children[0].material.color.clone()//standard color
            // scenes.maldivesCube.hoveringColor = new THREE.Color( 0xff0000 )//hovering color
            // scenes.maldivesCube.selectedColor = new THREE.Color( 0x0000ff )//selected color
        
            //Uncomment From here -----------------------------------------------------------
            //Setting up model for region selection
            let regionSelectionModel = models[key].clone(true) 
            // regionSelectionModel.scale.set(0.15,0.15,0.15)
            // regionSelectionModel.scale.set(0.1,0.1,0.1)
            regionSelectionModel.scale.set(0.38,0.38,0.38)
            regionSelectionModel.children[0].castShadow = true
            scenes.maldivesScene.add(regionSelectionModel)
            // console.log(regionSelectionModel);
        
        
            //Setting up and filtering regions and setting up region state colors
            let maldivesRegions = regionSelectionModel.children.slice() //Region references
            let base = maldivesRegions.splice(0,1)//Removes sealine from regions references
            // base.position.y = -0.5
            // console.log(regionSelectionModel);
            // console.log(maldivesRegions);
            console.log(regionSelectionModel);
        
            for (let i = 0; i < maldivesRegions.length; i++) {
                const region = maldivesRegions[i];
                
            

                region.name = region.name.toLowerCase()
                // region.name = region.name.replaceAll('_',' ')
                // region.name = region.name + ' Province'
            
                // scenes.maldivesRegionBoxes[i].position.y = i * -5   
                scenes.maldivesRegionBoxes[i].name = region.name
                scenes.maldivesRegionBoxes[i].position.copy( region.position )
            
                // console.log(region.position);
                // console.log(regionSelectionModel.position);
                const worldPos = new THREE.Vector3()
                region.getWorldPosition(worldPos)
                // console.log(region.localToWorld(region.position));
                // console.log(worldPos);
                // console.log(worldPos.multiplyScalar(1.9));
                // scenes.maldivesRegionBoxes[i].position.copy( worldPos )
            
                // scenes.maldivesRegionBoxes[i].add(region)
                // region.position.set(0,0,0)
                // region.scale.set(0.3,0.3,0.3)
            
                // console.log(region.position);
                // console.log(scenes.maldivesRegionBoxes[i].position);
            
            
                // region.standardMaterial = region.children[0].material.clone()//standard material
                // console.log(region.standardMaterial);
                // region.children[0].material = region.standardMaterial
            
                scenes.maldivesRegionBoxes[i].regionMaterial = region.children[0].material.clone()
                scenes.maldivesRegionBoxes[i].regionPosition = region.position
                region.children[0].material = scenes.maldivesRegionBoxes[i].regionMaterial
            
                scenes.maldivesRegionBoxes[i].standardColor = scenes.maldivesRegionBoxes[i].regionMaterial.color
                scenes.maldivesRegionBoxes[i].hoveringColor = new THREE.Color( 0xffffff )
                scenes.maldivesRegionBoxes[i].selectedColor = new THREE.Color( 0x3c5fff )
                
            }
            
        
            // model.castShadow = true
            // model.receiveShadow = true
        
            // scenes.setMaldivesRegions(maldivesRegions)
        
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            // if(loadedPercentage >= 1){ //if loadedPercentage is 1, then the survey can start.
            //     //Call function to start the survey
            //     main.startSurvey()
            // }
        }
    )
    //srilanka_cartoon_map
    gltfloader.load(
        'srilanka_cartoon_map.glb',
        (gltf) =>
        {
            const key = 'sriLankaMap'
            let model = gltf.scene
        
            models[key] = model.children[1]
        
            let countrySelectionModel = models[key].clone(true)
            countrySelectionModel.scale.set(0.18,0.18,0.18)
            countrySelectionModel.position.set(0.24,0,0.1)
            countrySelectionModel.castShadow = true
            // console.log(countrySelectionModel);
        
        
            scenes.sriLankaCube.add(countrySelectionModel)
        
        
            //Storing state colors as new properties
            scenes.sriLankaCube.regionMaterial = countrySelectionModel.material //Material for all regions
            countrySelectionModel.material.needsUpdate = true
        
            scenes.sriLankaCube.standardMap = new THREE.TextureLoader().load('sri_lanka_standard.png')
            scenes.sriLankaCube.standardMap.needsUpdate = true
            scenes.sriLankaCube.standardMap.flipY = false
            scenes.sriLankaCube.regionMaterial.map = scenes.sriLankaCube.standardMap
        
            scenes.sriLankaCube.hoveringMap =  new THREE.TextureLoader().load('sri_lanka_hovering.png')
            scenes.sriLankaCube.hoveringMap.needsUpdate = true
            scenes.sriLankaCube.hoveringMap.flipY = false
            
            scenes.sriLankaCube.selectedMap = new THREE.TextureLoader().load('sri_lanka_selected.png')
            scenes.sriLankaCube.selectedMap.needsUpdate = true
            scenes.sriLankaCube.selectedMap.flipY = false
        
        
        
            // console.log(models['sriLankaMap']);
            
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            // if(loadedPercentage >= 1){ //if loadedPercentage is 1, then the survey can start.
            //     //Call function to start the survey
            //     main.startSurvey()
            // }
        }
    )
    //maldives_cartoon_map
    gltfloader.load(
        'maldives_cartoon_map.glb',
        (gltf) =>
        {
            const key = 'maldivesMap'
            let model = gltf.scene
        
            models[key] = model.children[0]
            // console.log(models['maldivesMap']);
            let countrySelectionModel = models[key].clone(true)
            countrySelectionModel.scale.set(0.06,0.06,0.06)
            countrySelectionModel.position.set(0.02,0,-0.5)
            countrySelectionModel.children[3].castShadow = true
            // console.log(countrySelectionModel);
        
        
            scenes.maldivesCube.add(countrySelectionModel)
        
        
            //Storing state colors as new properties
            scenes.maldivesCube.regionMaterial = countrySelectionModel.children[0].material //Material for all regions
            // countrySelectionModel.regionMaterial.needsUpdate = true
        
            // scenes.maldivesCube.regionMaterial = countrySelectionModel.children[0].material//Material for all regions
        
            scenes.maldivesCube.standardColor = countrySelectionModel.children[0].material.color.clone()//standard color
            scenes.maldivesCube.hoveringColor = new THREE.Color( 0xffffff )//hovering color
            scenes.maldivesCube.selectedColor = new THREE.Color( 0x3c5fff )//selected color
        
            // scenes.maldivesCube.standardMap = new THREE.TextureLoader().load('maldives_standard.png')
            // scenes.maldivesCube.standardMap.needsUpdate = true
            // scenes.maldivesCube.standardMap.flipY = false
            // scenes.maldivesCube.regionMaterial.map = scenes.maldivesCube.standardMap
        
            // scenes.maldivesCube.hoveringMap =  new THREE.TextureLoader().load('maldives_hovering.png')
            // scenes.maldivesCube.hoveringMap.needsUpdate = true
            // scenes.maldivesCube.hoveringMap.flipY = false
            
            // scenes.maldivesCube.selectedMap = new THREE.TextureLoader().load('maldives_selected.png')
            // scenes.maldivesCube.selectedMap.needsUpdate = true
            // scenes.maldivesCube.selectedMap.flipY = false
        
        
        
            // console.log(models['maldivesMap']);
            
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            // if(loadedPercentage >= 1){ //if loadedPercentage is 1, then the survey can start.
            //     //Call function to start the survey
            //     main.startSurvey()
            // }
        }
    )
    //Loading tree models
    gltfloader.load(
        'Tree.glb',
        (gltf) =>
        {
            const key = 'Tree1'
            let model = gltf.scene
            model.name = key
            model.position.set(0, -0.3, 0)
            model.scale.set(0.25,0.25,0.25)

            models[key] = model
            SetTreeMaterial(model);

            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }

            models['Tree2'] = models[key].clone(true)
            models['Tree3'] = models[key].clone(true)
            models['Tree4'] = models[key].clone(true)
            models['Tree5'] = models[key].clone(true)
            models['Tree6'] = models[key].clone(true)
            models['Tree7'] = models[key].clone(true)
            models['Tree8'] = models[key].clone(true)
            models['Tree9'] = models[key].clone(true)
            models['Tree10'] = models[key].clone(true)
        }
    )

    //#region Loading Cloud models
    gltfloader.load(
        'cloud.glb',
        (gltf) =>
        {
            const key = 'cloud1'
            let model = gltf.scene
            model.name = key
            model.position.set(0, -0.3, 0)
            model.scale.set(0.05,0.05,0.05)

            models[key] = model

            let mesh = model.children[0].children[0].children[0].children[0];
            var col = mesh.material.color;
            var newMat = new THREE.MeshLambertMaterial( {color : col });
            mesh.material = newMat;
            mesh.material.transparent = true
            model.material = mesh.material

            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            //models['cloud2'] = models[key].clone(true)
            //models['cloud3'] = models[key].clone(true)
        }
    )
    
    gltfloader.load(
        'cloud.glb',
        (gltf) =>
        {
            const key = 'cloud2'
            let model = gltf.scene
            model.name = key
            model.position.set(0, -0.3, 0)
            model.scale.set(0.05,0.05,0.05)

            models[key] = model

            let mesh = model.children[0].children[0].children[0].children[0];
            var col = mesh.material.color;
            var newMat = new THREE.MeshLambertMaterial( {color : col });
            mesh.material = newMat;
            mesh.material.transparent = true
            model.material = mesh.material

            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )

    gltfloader.load(
        'cloud.glb',
        (gltf) =>
        {
            const key = 'cloud3'
            let model = gltf.scene
            model.name = key
            model.position.set(0, -0.3, 0)
            model.scale.set(0.05,0.05,0.05)

            models[key] = model

            let mesh = model.children[0].children[0].children[0].children[0];
            var col = mesh.material.color;
            var newMat = new THREE.MeshLambertMaterial( {color : col });
            mesh.material = newMat;
            mesh.material.transparent = true
            model.material = mesh.material

            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    
    //#endregion
}
loadInitialModels()

export function loadPostModels(){
    if(postModelsLoaded)
        return
    else
        postModelsLoaded = true

    //Distant friend
    gltfloader.load(
        'Models/Animation_V09.gltf',
        (gltf) =>
        {
            const key = 'distantFriend'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.3,.3,.3)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                    child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Siblings
    gltfloader.load(
        'Models/siblings.gltf',
        (gltf) =>
        {
            const key = 'siblings'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                    child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Friends
    gltfloader.load(
        'Models/Friends.gltf',
        (gltf) =>
        {
            const key = 'friends'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Community
    gltfloader.load(
        'Models/Community.gltf',
        (gltf) =>
        {
            const key = 'community'
            //animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.035,.035,.035)     //prev (.075,.075,.075)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Different Language speaker
    gltfloader.load(
        'Models/dif_language5.gltf',
        (gltf) =>
        {
            const key = 'dif_language'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.055,.055,.055)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                console.log("Mesh name = ", child.name, ", id = ", child.id)
                // if (child.isMesh && (child.id == 795 || child.id == 796 || child.id == 794 || child.id == 800) ){
                    
                //     let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                //     child.material = toonMaterial;
                //     child.castShadow = true;
                // }

                if(child.isMesh){
                    if(child.name == "character02001" || child.name == 'character02' || child.name == 'bubbletext3003' || child.name == 'bubbletext2' || child.name == 'bubbletext3' || child.name =='bubbletext3001'){
                        child.material = shaderMaterial
                    }
                    child.castShadow = true
                }
                

                
            });
            

    
            console.log(gltf)
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Home country
    gltfloader.load(
        'Models/Home Country.gltf',
        (gltf) =>
        {
            const key = 'home_country'
            //animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.035,.035,.035)     //prev (.075,.075,.075)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Temple
    gltfloader.load(
        'Models/Temple.gltf',
        (gltf) =>
        {
            const key = 'temples'
            //animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            const scale = 0.055
            model.scale.set(scale,scale,scale)     //prev (.065,.065,.065)
            model.position.set(0,-.6, 0)
            model.rotation.set(0, MathUtils.degToRad(-25),0)  //-75 for old model
    
            model.traverse((child) => {
                if (child.isMesh){
                    if(child.name == 'Temple_Base'){
                        child.material = lowFresnelMaterial
                    }
                    child.castShadow = true
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Religious Beliefs
    gltfloader.load(
        'Models/religious belief2.gltf',
        (gltf) =>
        {
            const key = 'religious_belief'
            //animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            const scale = 0.035
            model.scale.set(scale,scale,scale)     //prev (.035,.035,.035)
            model.position.set(0, -0.4, 0)
            model.rotation.y = MathUtils.degToRad(0)

            model.traverse((child) => {
                if(child.isMesh){
                    // console.log("Mesh name = ", child.name)
                    child.castShadow = true
                    if(child.name == 'Character02'){    //Main Character
                        var toon = new THREE.MeshToonMaterial({color:0xFFFF00, gradientMap : tex})
                        child.material = shaderMaterial
                    }
                    if(child.name == 'shine'){  //Back strips
                        var alpha = new THREE.TextureLoader().load('Textures/ReligiousBeleif_Texture/lightStrips_Alpha.png')
                        alpha.flipY = false
                        alpha.needsUpdate = true
                        var mat = new THREE.MeshToonMaterial({ color : 0xFFFF00, alphaMap : alpha, transparent : true})
                        child.material = mat
                    }
                    if(child.name == 'orb01'){  //Religion Indicators
                        var t = new THREE.TextureLoader().load('Textures/ReligiousBeleif_Texture/relsphere.png')
                        t.flipY = false
                        t.needsUpdate = true
                        var m = new THREE.MeshToonMaterial({ map : t, opacity : 1, transparent :true, gradientMap : tex})
                        child.material = m
                    }       
                    if(child.name == 'orb01001'){   //Religion indicators   //Not needed now
                        var t = new THREE.TextureLoader().load('Textures/ReligiousBeleif_Texture/relsphere.png')
                        t.flipY = false
                        t.needsUpdate = true
                        var mat = new THREE.MeshToonMaterial({map : t, opacity : 0, transparent : true, gradientMap : tex})
                        child.material = mat
                    }
                    if(child.name == 'SmallGlow'){  //Small Glow
                        var t = new THREE.TextureLoader().load('Textures/ReligiousBeleif_Texture/SmallGlow.png')
                        var alpha = new THREE.TextureLoader().load('Textures/ReligiousBeleif_Texture/SmallGlow_alpha.png')
                        t.flipY = false
                        t.needsUpdate = true
                        alpha.flipY = false
                        var mat = new THREE.MeshToonMaterial( {map : t, opacity : 0.65, transparent : true, gradientMap : tex, alphaMap : alpha})
                        child.material = mat
                    }
                }
            })
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Sofa
    gltfloader.load(
        'Models/Sofa.gltf',
        (gltf) =>
        {
            const key = 'sofa'
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)
            models[key] = model
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Sofa small
    gltfloader.load(
        'Models/SofaSmall.gltf',
        (gltf) =>
        {
            const key = 'sofasmall'
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)
            models[key] = model
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Letter
    gltfloader.load(
        'Models/letter.gltf',
        (gltf) =>
        {
            const key = 'letter'
            let model = gltf.scene
            model.name = key
            model.scale.set(0.2,0.2,0.2)
            model.position.set(0,-0.6, 0)
            models[key] = model

            model.traverse((child) => {
                if (child.isMesh){
                    if(child.name == 'shine'){
                        var alpha = new THREE.TextureLoader().load('Textures/letter_shine_alpha.png')
                        alpha.flipY = false
                        alpha.needsUpdate = true
                        var mat = new THREE.MeshToonMaterial({alphaMap : alpha,opacity : 0.95, transparent : true, gradientMap : tex})
                        child.material = mat
                    }
                }
            });

            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Land stage 3
    gltfloader.load(
        'Models/LandStage3.gltf',
        (gltf) =>
        {
            const key = 'landstage3'
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-0.72, 0)
            models[key] = model
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )

    //adventurer - High LOC
    gltfloader.load(
        'adventurer.gltf',
        (gltf) =>
        {
            const key = 'adventurer'
            let model = gltf.scene
            model.name = key
            model.scale.set(0.3,0.3,0.3)
            model.position.set(-1.5,-0.6, 2)
            models[key] = model
            // console.log(model);
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                    

                    if(child.name == "SimpleCharacter" ){
                        child.material = shaderMaterial
                    }
                }
            });
            // console.log(gltf);
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }

            model.animMixer = new THREE.AnimationMixer(model)
            const clips = gltf.animations

            clips.forEach(clip => {
                model.animMixer.clipAction(clip).play();
            });
        }
    )

    //Map maker - Mid LOC
    gltfloader.load(
        'map_maker.gltf',
        (gltf) =>
        {
            const key = 'mapMaker'
            let model = gltf.scene
            model.name = key
            model.scale.set(0.3,0.3,0.3)
            model.position.set(0,-0.59, 2)
            models[key] = model
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                    

                    if(child.name == "SimpleCharacter002" ){
                        child.material = shaderMaterial
                    }
                }
            });
            // console.log(gltf);
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
            model.animMixer = new THREE.AnimationMixer(model)
            const clips = gltf.animations

            clips.forEach(clip => {
                model.animMixer.clipAction(clip).play();
            });
        }
    )

    //Change seeker - Low LOC
    gltfloader.load(
        'change_seeker.gltf',
        (gltf) =>
        {
            const key = 'changeSeeker'
            let model = gltf.scene
            model.name = key
            model.scale.set(0.3,0.3,0.3)
            model.position.set(-0.75,-0.61, 2)

            console.log(model.children[1].position)
            // model.children[1].position.x = 4
            // model.children[1].position.z = -4
            // gltf.animations[1].tracks[1].values.forEach(value)

            models[key] = model
            // console.log(model);
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true

                    
                    if(child.name == "SimpleCharacter001" ){
                        child.material = shaderMaterial
                    }
                }
            });
            console.log(gltf);
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }

            model.animMixer = new THREE.AnimationMixer(model)
            const clips = gltf.animations

            clips.forEach(clip => {
                model.animMixer.clipAction(clip).play();
            });
        }
    )

    //Love Emoji
    gltfloader.load(
        'Models/Emojis/love/scene.gltf',
        (gltf) =>
        {
            const key = 'centerEmoji'
            let model = gltf.scene
            model.name = key
            model.scale.set(.06,.06,.06)
            model.position.set(1.5,-0.3, 0)
            models[key] = model
            model.traverse((child) => {
                if (child.isMesh){
                    child.castShadow = true
                }
            });
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    loadFillerModels()
}

function loadFillerModels(){
    //Mother 2
    gltfloader.load(
        'Models/mom.gltf',
        (gltf) =>
        {
            const key = 'mother2'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)

            model.traverse((child) => {
                if (child.isMesh){
                    let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                    child.material = outerCharacterShaders;
                    child.castShadow = true;
                }
            });

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Father 2
    gltfloader.load(
        'Models/father.gltf',
        (gltf) =>
        {
            const key = 'father2'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(0.045,0.045,0.045)     //prev (0.04,0.04,0.04) = player size
            model.position.set(0,-.6, 0)

            //
            model.traverse((child) => {
                if (child.isMesh){
                    if(child.name == 'Father'){
                        child.material = outerCharacterShaders;
                        child.castShadow = true;
                    }
                    // else if(child.name == 'EyeGlass001'){
                    //     var glassMat = new THREE.MeshLambertMaterial({color: 0xFFFFFF, transparent : true, opacity : 0.5});
                    //     child.material = glassMat;
                    //     child.castShadow = true;
                    // }
                    // else if(child.name == 'Moustache'){
                    //     var moustacheMat = new THREE.MeshToonMaterial({color: 0xafafaf, gradientMap: tex});
                    //     child.material = moustacheMat;
                    //     child.castShadow = true;
                    // }
                    else{
                        var transparentMat = new THREE.MeshBasicMaterial({transparent: true, opacity: 0});
                        child.material = transparentMat;
                    }
                }
            });

            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Siblings 2
    gltfloader.load(
        'Models/siblings.gltf',
        (gltf) =>
        {
            const key = 'siblings2'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name = key
            model.scale.set(.075,.075,.075)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    let toonMaterial = new THREE.MeshToonMaterial({ color : 0xFFC332, gradientMap : tex});
                    child.material = outerCharacterShaders;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //Friend 1
    // gltfloader.load(
    //     'Models/Friends.gltf',
    //     (gltf) =>
    //     {
    //         const key = 'friends1'
    //         animations[key] = gltf.animations
    //         let model = gltf.scene
    //         model.name =  key
    //         model.scale.set(.075,.075,.075)
    //         model.position.set(0,-.6, 0)
    
    //         model.traverse((child) => {
    //             if (child.isMesh){
    //                 child.material = shaderMaterial;
    //                 child.castShadow = true;
    //             }
    //         });
    
    //         models[key] = model
    //         if(preLoadModels.includes(key)){
    //             loadedPercentage += (1/numberOfAssets)
    //             loadingBar.animate(loadedPercentage)
    //         }
    //     }
    // )

    LoadAndAssignModel('Models/Friends.gltf', 'friends1', 0.075)
    LoadAndAssignModel('Models/Friends.gltf', 'friends2', 0.075)

    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend1', 0.3)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend2', 0.3)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend3', 0.3)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend4', 0.3)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend5', 0.25)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend6', 0.25)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend7', 0.25)
    LoadAndAssignModel('Models/Animation_V09.gltf', 'friend8', 0.25)

    //House 1
    gltfloader.load(
        'Models/house.gltf',
        (gltf) =>
        {
            const key = 'house1'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(.3,.3,.3)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    //child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //House 2
    gltfloader.load(
        'Models/house.gltf',
        (gltf) =>
        {
            const key = 'house2'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(.23,.23,.23)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    //child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //House 3
    gltfloader.load(
        'Models/house.gltf',
        (gltf) =>
        {
            const key = 'house3'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(.18,.18,.18)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    //child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
    //House 4
    gltfloader.load(
        'Models/house.gltf',
        (gltf) =>
        {
            const key = 'house4'
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(.13,.13,.13)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    //child.material = shaderMaterial;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
}

function LoadAndAssignModel(directory, key, scale){
    scale = parseFloat(scale)
    gltfloader.load(
        directory,
        (gltf) =>
        {
            animations[key] = gltf.animations
            let model = gltf.scene
            model.name =  key
            model.scale.set(scale,scale,scale)
            model.position.set(0,-.6, 0)
    
            model.traverse((child) => {
                if (child.isMesh){
                    child.material = outerCharacterShaders;
                    child.castShadow = true;
                }
            });
    
            models[key] = model
            if(preLoadModels.includes(key)){
                loadedPercentage += (1/numberOfAssets)
                loadingBar.animate(loadedPercentage)
            }
        }
    )
}

function SetTreeMaterial(scene){
    scene.traverse( function(object){
        if(object.material){
            var col = 0x178BD4;     //prev 0xffa211
            var mat = new THREE.MeshToonMaterial( {color : col, gradientMap : tex});
            object.material = mat;
            object.castShadow = true;
            object.receiveShadow = true;
        }
    });
}

//
//      end of Loading models

export function getModel(modelKey){
    return models[modelKey]
}

export function getPlayerAnimations(){
    return animations['playerCharacter']
}

export function getOtherCharacterAnimations(animationKey){
    return animations[animationKey]
}

export function getAnimationIds(key){
    return animationId[key];
}
